<template>
    <r-e-dialog title="新增人员" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel" width="600px">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="formPublish.name" placeholder="请填写姓名" />
            </el-form-item>
            <el-form-item label="手机" prop="phone">
                <el-input v-model="formPublish.phone" placeholder="请填写手机号码"/>
            </el-form-item>
            <el-form-item label="人员类型"  prop="" >
                        <div style="width: 250px;">
                            <el-select v-model="formPublish.type" placeholder="请选择" style="width: 100%;">
                                <el-option label="中介" value="中介"/>
                                <el-option label="装修/软装" value="装修/软装"/>
                                <el-option label="施工" value="施工"/>
                                <el-option label="其他" value="其他"/>
                            </el-select>
                        </div>
                    </el-form-item>
            <el-form-item label="所属单位" prop="company">
                <el-input v-model="formPublish.company"  placeholder="请填写单位名称" />
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import { MessageSuccess, MessageWarning } from "@custom/message";
    // import { saveAticity } from "@/api/activity-management";
    import{addUser} from "@/api/staff-management"
    import { ParseIntFormat} from "@custom/index";
    export default {
        name: "dialog-save-activity",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    name: '',
                    phone: '',
                    type: [],
                    company: '',
                },
                rules: {
                    name: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
                    phone: [{required: true, message: '手机号不能为空', trigger: 'blur'}],
                    type: [{required: true, message: '人员类型不能为空', trigger: 'blur'}],
                    company: [{required: true, message: '单位名称不能为空', trigger: 'blur'}],
                },
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            }
        },
        async created() {
        },
        methods: {
            async openDialog(data = null) {
                this.dialogVisible = true;
            },
            

            handleSubmitPublish() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let {name, phone, type, company}  = that.formPublish;
                        // phone = ParseIntFormat(phone);
                        const loadingOptions = that.loadingOptions;
                        const loading = that.$loading(loadingOptions);
                        let data = {name, phone, type,company};
                        addUser(data).then(res => {
                            MessageSuccess("添加成功");
                            that.handleCancel();
                        }).finally(()=>loading.close());
                    } else MessageWarning("请填写必填值", 1000);
                });
            },
            handleCancel() {
                this.dialogVisible = false;
                this.formPublish = {
                    name: '',
                    company: '',
                    phone: '',
                    type: [],
                }
                //2023/03/21 调用父组件方法 刷新未支付房租账单列表
                this.$emit('handleSearch');
            },
        },
        watch:{}
    }
</script>

<style lang="scss" scoped>
    .el-select {
        width: 100%;
    }

    .flex {
        display: flex;

        /deep/ .el-form-item {
            flex: 1;
            white-space: nowrap;
        }

        /deep/ .tag-group {
            white-space: normal;

            .el-tag {
                margin: 0 VW(5px) VH(5px) 0;
                cursor: pointer;
            }
        }

        /deep/ .samplePhotos {
            white-space: normal;
        }
    }
</style>